/* eslint-disable react/no-unused-state, prefer-destructuring, dot-notation, prettier/prettier, no-underscore-dangle, no-unused-vars */
import {
  css,
  JsonResponse,
  QueryData,
  SupersetClient,
  supersetTheme,
  // @ts-ignore
  t,
  withTheme,
} from '@superset-ui/core';
import React, {
  useEffect,
  useState,
  // @ts-ignore
  useCallback,
  // @ts-ignore
  useMemo,
  memo,
  useRef,
} from 'react';
import { useStore } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { Col, Row } from 'src/components';
// @ts-ignore
import { Form, notification } from 'antd';
// @ts-ignore
import Signal, { SignalStatus } from 'src/types/Signal';

import Button from 'src/components/Button';
// @ts-ignore
import { ChartDataResponseResult, LegacyQueryData } from '@superset-ui/core/src/types';
import { ColumnMeta } from '@superset-ui/chart-controls';
import Alert from 'src/components/Alert';
import { addSuccessToast, addDangerToast, addWarningToast, addInfoToast } from 'src/components/MessageToasts/actions';
import {
  SignalAlertsProps,
  SignalAlertConfigurationProps,
  SignalAlertConfigurationEntity,
  SignalMeasureContentEntity,
  TimeGranularity,
} from './signalAlertsTypes';
import { camelCaseKeysToUnderscore, underscoreToCamelCase } from './utils';
import SignalAlertConfigurationControl, { formatDateKeepTimeChangeZoneToUTC } from './SignalAlertConfigurationControl';
import { normalizeQueryData } from './signalAlertsHelper';
import {
  WARNING_MESSAGE_FORM_DATA_CHANGED,
  INFO_MESSAGE_QUERY_DATA_CHANGED,
  ERROR_MESSAGE_GRANULARITY_NOT_VALID,
  INFO_MESSAGE_ALL_DETECTION_PAY_ATTENTION
} from './SignalAlertsMessageConfig';
import { DEFAULT_DELAY, MIN_DELAY, MAX_DELAY } from './Constant';

const is_verbose = false;

// #####################################################################################################
// ############################### Static private helper functions #####################################
// #####################################################################################################

const addToastForSignalNotification = (type: string, msg: string, desc: string) => {
  if (type === 'success') {
    return addSuccessToast(`${msg}, ${desc}`);
  }

  if (type === 'error') {
    return addDangerToast(`${msg}, ${desc}`);
  }

  if (type === 'warning') {
    return addWarningToast(`${msg}, ${desc}`);
  }
  return addInfoToast(`${msg}, ${desc}`);
};

const fetchAlertValue = async (sliceId: number) => {
  const endpoint = `/api/v1/signal/related/chart_alert/${sliceId}`;
  const res = await SupersetClient.get({ endpoint }).then(({ json }) => json.result);
  return res;
};

const validateQuery = async (value: SignalAlertConfigurationEntity) => {
  const schema = value.schemaPerm?.split('.')[1]?.replaceAll(/[[]]/g, '');
  const requestJson = {
    database_id: value.dbsIdFk,
    // eslint-disable-next-line object-shorthand
    schema: schema,
    sql: `DESCRIBE (${value.query?.replaceAll('\'{end_time}\'', 'now()') ?? 'NOT VALID SQL'})`,
    queryLimit: 1,
  };
  const endpoint = `/nezha/signal_sql_json/`;
  const res = await SupersetClient.post({
    // eslint-disable-next-line object-shorthand
    endpoint: endpoint,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(requestJson),
  }).then(({ json }) => json.result);
  return res;
}

const saveAlert = async (value: SignalAlertConfigurationEntity) => {
  if (is_verbose) console.log('=== on @saveAlert() -> ===');
  const query_data = JSON.stringify(value.queryData);
  const requestJson = camelCaseKeysToUnderscore(value);
  requestJson['query_data'] = query_data;
  if (is_verbose) console.log('=== on @saveAlert() -> #requestJson ===');
  if (is_verbose) console.log(requestJson);

  const res = await SupersetClient.post({
    endpoint: `/api/v1/signal/`,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(requestJson),
  }).then((response: JsonResponse) => {
    if (is_verbose) console.log('=== on @saveAlert() -> /api/v1/signal/ -> POST -> response ===');
    if (is_verbose) console.log(response);
    return response;
  });
  return res;
};

const updateAlert = async (value: SignalAlertConfigurationEntity) => {
  if (is_verbose) console.log('=== on @updateAlert() -> ===');
  const query_data = JSON.stringify(value.queryData);
  const requestJson = camelCaseKeysToUnderscore(value);

  requestJson['owners'] = requestJson['owners'].map((owner: any) => owner['id']);
  requestJson['query_data'] = query_data;
  if (is_verbose) console.log('=== on @updateAlert() -> #requestJson ===');
  if (is_verbose) console.log(requestJson);

  const res = await SupersetClient.put({
    endpoint: `/api/v1/signal/${value.id}`,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(requestJson),
  }).then((response: JsonResponse) => {
    if (is_verbose) console.log('=== on @updateAlert() -> /api/v1/signal/ -> PUT -> response ===');
    if (is_verbose) console.log(response);
    return response;
  });
  return res;
};


// #######################################
// ###### SignalAlertsControl Class ######
// #######################################

const SignalAlertsControl = memo(function SignalAlertsControl(
  props: SignalAlertsProps,
) {
  // @ts-ignore
  const [columnsFormatInfo, setColumnsFormatInfo] = useState<ColumnMeta[]>(props.columnsFormatInfo);
  const [value, setValue] = useState(props.value);
  const [savedValue, setSavedValue] = useState<SignalAlertConfigurationEntity>();
  // @ts-ignore
  const [showAlertAfterSaveOrUpdateSuccess, setShowAlertAfterSaveOrUpdateSuccess] = useState(false);

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [isDisabled, setIsDisabled] = useState(props.isDisabled);
  const [dbsIdFk, setDbsIdFk] = useState(props.dbsIdFk);

  const [oldQueryData, setOldQueryData] = useState<QueryData>();
  // @ts-ignore
  const [queryData, setQueryData] = useState(props.queryData);
  if (is_verbose) console.log('=== SignalAlertsControl -> init queryData, ===');
  if (is_verbose) console.log(queryData);

  const [oldParams, setOldParams] = useState<any>();
  // @ts-ignore
  const [params, setParams] = useState(props.params);
  // @ts-ignore
  const [slicePerm, setSlicePerm] = useState(props.slicePerm);
  // @ts-ignore
  const [sliceSchemaPerm, setSliceSchemaPerm] = useState(props.sliceSchemaPerm);

  if (is_verbose) console.log('=== SignalAlertsControl -> init params, ===');
  if (is_verbose) console.log(params);
  if (is_verbose) console.log('=== SignalAlertsControl -> init oldParams, ===');
  if (is_verbose) console.log(oldParams);

  const [isParamsChanged, setIsParamsChanged] = useState(false);
  const [isQueryDataChanged, setIsQueryDataChanged] = useState(false);
  // @ts-ignore
  const [isSignalMeasureContentListChanged, setIsSignalMeasureContentListChanged] = useState(false);

  const store = useStore();

  const maxSeriesNumber = 5;

  /**
   * parmas is the alias of form_data, so oldParams(saved) not equals params(chart form_data) means the chart form_data changed
   *   -> need to noitify user the chart form_data changed, and the saved alert is not the latest one.
   *      If user want to use new params details to create a new alert, need to delete the old one first.
   * @param params 
   * @param oldParams 
   * @returns 
   */
  const checkIsParamsNotEqualsOldParams = (
    params: any,
    oldParams: any,
  ) => {
    // eslint-disable-next-line dot-notation
    if (params && oldParams) {
      const copiedParams = { ...params };
      delete copiedParams['time_range'];
      const copiedOldParams = { ...oldParams };
      delete copiedOldParams['time_range'];
      return JSON.stringify(copiedParams) !== JSON.stringify(copiedOldParams);
    }
    if (params === undefined && oldParams === undefined) {
      return false;
    }
    return true;
  };

  /**
   * oldQueryData(saved) not equals queryData(chart queryData) means the chart queryData changed
   *   -> need to noitify user the chart queryData changed, and the saved alert is not refers to the latest queryData.
   *      If user want to use new queryData details to create a new alert, need to delete the old one first.
   * @param oldQueryData 
   * @param newQueryData 
   * @returns 
   */
  const checkIsQueryDataChanged = (oldQueryData: QueryData, newQueryData: QueryData) => {
    if (oldQueryData && newQueryData) {
      return JSON.stringify(oldQueryData['data']) !== JSON.stringify(newQueryData['data']);
    }
    if (oldQueryData === undefined && newQueryData === undefined) {
      return false;
    }
    return true;
  };

  /**
   * if oldMeasureContentList is not equals to measureContentList, means the setting is changed, so should call updateAlert, else call saveAlert.
   * @param oldMeasureContentList 
   * @param measureContentList 
   * @returns 
   */
  const checkIsSignalMeasureContentListChanged = (oldMeasureContentList?: SignalMeasureContentEntity[], measureContentList?: SignalMeasureContentEntity[]) => {
    if (oldMeasureContentList && measureContentList) {
      return JSON.stringify(oldMeasureContentList) !== JSON.stringify(measureContentList);
    }
    if (oldMeasureContentList === undefined && measureContentList === undefined) {
      return false;
    }
    return true;
  }

  // const checkIsSeriesLimitValid = () => {
  //   if (params === undefined) {
  //     return false;
  //   }
  //   const limit = Number.parseInt(params.limit, 10);
  //   if (Number.isNaN(limit)) {
  //     return false;
  //   }
  //   return limit > 0 && limit <= 5;
  // };

  const checkIsGranValid = () => {
    if (params === undefined) {
      return false;
    }
    const granularity = params['time_grain_sqla'];
    const validGranularityValues = Object.values(TimeGranularity);
    if (granularity === undefined || granularity === null || validGranularityValues.indexOf(granularity) === -1) {
      return false;
    }
    return true;
  };

  /**
   * 
   * @returns 
   */
  const checkIsReadonly = () => {
    if (isDisabled || loading || saving) {
      return true;
    }
    if (!oldQueryData || !oldParams) {
      return true;
    }
    // if (!value && (!checkIsSeriesLimitValid() || !checkIsGranValid())) {
    if (!value && (!checkIsGranValid())) {
      return true;
    }
    return false;
  }

  const getUsedParams = () => {
    if (oldParams) {
      return oldParams;
    }
    return params;
  };

  const getUsedQueryData = () => {
    if (!isParamsChanged && oldQueryData && queryData) {
      return queryData;
    }

    if (oldQueryData) {
      return oldQueryData;
    }
    return queryData;
  }

  // @ts-ignore
  const getUsedValue = () => {
    if (savedValue) {
      return savedValue;
    }
    return value;
  }

  const checkIsReadyToSave = (isShowNotification: boolean, value?: SignalAlertConfigurationEntity) => {
    if (value === undefined) {
      return false;
    }

    if (value?.mode === undefined || value.mode.length === 0) {
      return false;
    }

    if (value?.description === undefined || value.description.length === 0) {
      return false;
    }

    if (value.signalMeasureContentList === undefined || value.signalMeasureContentList.length === 0) {
      if (isShowNotification) {
        // showNotification('error', 'invalid measure series configuration', 'No measure series selected');
        store.dispatch(addToastForSignalNotification('error', 'Invalid measure series configuration', 'No measure series selected'));
      }
      return false
    }
    if (value.signalName === undefined || value.signalName.length === 0) {
      return false;
    }
    const signalNameInvalidPattern = /^[A-Za-z0-9!"#$%&'()*+,-.:;<=>?@[\]^_{|}`~ ]+$/g;
    if (!signalNameInvalidPattern.test(value.signalName)) {
      return false;
    }

    if (value.signalGranularity === undefined || value.signalGranularity.length === 0) {
      return false;
    }
    // validate recipient
    if (value.recipient === undefined || value.recipient.length === 0 || value.recipient === '[]') {
      return false;
    }
    const splittedRecipentArr = JSON.parse(value.recipient) as string[];
    const isEmail = (email: string): boolean => {
      // Regular expression to validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const isMicrosoftEmail = (email: string): boolean => {
      // Regular expression to validate email format
      const emailRegex = /^[^\s@]+@microsoft\.com$/;
      return emailRegex.test(email);
    }
    const allEmailsValid = splittedRecipentArr.every((recipient) => {
      const matched = isEmail(recipient) && isMicrosoftEmail(recipient);
      if (!matched && isShowNotification) {
        store.dispatch(addToastForSignalNotification('error', 'Invalid email address', `invalid email address: ${recipient}, please use valid microsoft email address.`));
        // showNotification('error', 'invalid email address', `invalid email address: ${recipient}, please use valid microsoft email address.`);
      }
      return matched;
    });
    if (!allEmailsValid) {
      return false;
    }

    // validate the delay is valid
    if (!value.delay || value.delay < MIN_DELAY || value.delay > MAX_DELAY) {
      return false;
    }

    return true;
  }

  /**
   * 
   * @param params 
   * @param value 
   */
  const handleOnSaveAlert = (params: any, value?: SignalAlertConfigurationEntity) => {
    if (!checkIsReadyToSave(true, value)) {
      return;
    }

    // start handleOnSaveAlert
    if (is_verbose) console.log("=== on @handleOnSaveAlert() -> ===");
    if (is_verbose) console.log("=== on @handleOnSaveAlert() -> #value ===");
    if (is_verbose) console.log(value);
    if (is_verbose) console.log("=== on @handleOnSaveAlert() -> #value.sliceIdFk ===");
    if (is_verbose) console.log(value?.sliceIdFk);
    if (is_verbose) console.log("=== on @handleOnSaveAlert() -> #params ===");
    if (is_verbose) console.log(params);


    if (value && (value.sliceIdFk || props.params?.slice_id)) {
      if (is_verbose) console.log("=== on @handleOnSaveAlert() == start ===");
      // start saving signal alert
      setSaving(true);

      // restore the normalized queryData propertity format because we normalize the queryData before pass it to child SignalAlertConfigurationControl
      const valueRestoredQueryDataFormat: SignalAlertConfigurationEntity = {
        id: value.id,
        signalName: value.signalName,
        description: value.description ? value.description : '',
        subject: value.subject ? value.subject : undefined,
        recipient: value.recipient,
        mode: value.mode,
        measure: value.measure,
        dimension: value.dimension,
        series: value.series,
        status: value.status,
        dbsIdFk: value.dbsIdFk,
        // eslint-disable-next-line arrow-body-style
        signalMeasureContentList: value.signalMeasureContentList?.map((measureContent: SignalMeasureContentEntity) => {
          return {
            id: measureContent.id,
            signalIdFk: measureContent.signalIdFk,
            measure: measureContent.measure,
            dimension: measureContent.dimension,
            query: measureContent.query,
            dimensionSeries: JSON.stringify(camelCaseKeysToUnderscore(JSON.parse(measureContent.dimensionSeries))),
            detectionSetting: measureContent.detectionSetting,
            deleted: measureContent.deleted,
          };
        }),
        query: value.query,
        sliceIdFk: value.sliceIdFk ? value.sliceIdFk : props.params.slice_id,
        ingestionStartTime: value.ingestionStartTime,
        granularity: value.granularity,
        signalGranularity: value.signalGranularity,
        signalType: value.signalType,
        owners: value.owners,
        params: value.params,
        queryData: getUsedQueryData(),
        perm: value.perm ? value.perm : slicePerm,
        schemaPerm: value.schemaPerm ? value.schemaPerm : sliceSchemaPerm,
        delay: value.delay ? value.delay : DEFAULT_DELAY,
      };
      // if (savedValue && isSignalMeasureContentListChanged) {
      if (savedValue) {
        // update start
        validateQuery(valueRestoredQueryDataFormat).then((res) => {
          updateAlert(valueRestoredQueryDataFormat).then((res) => {
            // updating signal alert finished 
            setSaving(false);
            if (is_verbose) console.log(res);
            if (!valueRestoredQueryDataFormat.sliceIdFk) {
              console.error("=== on @handleOnSaveAlert() -> updateAlert -> valueRestoredQueryDataFormat.sliceIdFk is undefined ===");
            } else {
              // start to fetch latest saved signal alert valueRestoredQueryDataFormat
              setLoading(true);

              fetchAlertValue(valueRestoredQueryDataFormat.sliceIdFk).then((res) => {
                if (is_verbose) console.log("=== on handleOnSaveAlert -> updateAlert ===");
                if (is_verbose) console.log("=== on @handleOnSaveAlert() -> updateAlert -> fetchAlertValue: ===")
                if (is_verbose) console.log(res);
                let newSavedSignal = underscoreToCamelCase(res);
                newSavedSignal['queryData'] = JSON.parse(newSavedSignal['queryData']);
                newSavedSignal = newSavedSignal as SignalAlertConfigurationEntity;
                newSavedSignal['delay'] = Number.parseInt(newSavedSignal['delay'] as string, 10);
                if (is_verbose) console.log("=== on @handleOnSaveAlert() -> updateAlert -> newSavedSignal: ===");
                if (is_verbose) console.log(newSavedSignal);
                setValue(newSavedSignal);
                setSavedValue(newSavedSignal);
                setOldParams(JSON.parse(newSavedSignal.params));
                setOldQueryData(newSavedSignal.queryData);

                setIsParamsChanged(checkIsParamsNotEqualsOldParams(params, JSON.parse(newSavedSignal.params)));
                setIsQueryDataChanged(checkIsQueryDataChanged(queryData, newSavedSignal.queryData));

                // loading latest saved signal alert value finished
                setLoading(false);
                setShowAlertAfterSaveOrUpdateSuccess(true);
                store.dispatch(addToastForSignalNotification('success', 'Save signal', 'update signal success.'));
                // pre-refresh chart list 
                props.listPageRefreshData();
              }).catch((e) => {
                if (is_verbose) console.log(e);
                setLoading(false);
              });
            }
          }).catch((e) => {
            if (is_verbose) console.log(e);
            setSaving(false);
            store.dispatch(addToastForSignalNotification('error', 'Update alert failed', `update alert failed, with error message: ${e.statusText}`));
          });
        }).catch((e) => {
          if (is_verbose) console.log(e);
          setSaving(false);
          store.dispatch(addToastForSignalNotification('error', 'Update alert failed', `update alert failed, with error message: ${e.statusText}`));
        });

      } else {
        validateQuery(valueRestoredQueryDataFormat).then((res) => {
          saveAlert(valueRestoredQueryDataFormat).then((res) => {
            // saving signal alert finished 
            setSaving(false);
            if (is_verbose) console.log(res);
            if (!valueRestoredQueryDataFormat.sliceIdFk) {
              console.error("=== on @handleOnSaveAlert() -> saveAlert -> valueRestoredQueryDataFormat.sliceIdFk is undefined ===");
            } else {
              // start to fetch latest saved signal alert valueRestoredQueryDataFormat
              setLoading(true);
              fetchAlertValue(valueRestoredQueryDataFormat.sliceIdFk).then((res) => {
                if (is_verbose) console.log("=== on handleOnSaveAlert -> saveAlert ===");
                if (is_verbose) console.log("=== on @handleOnSaveAlert() -> saveAlert -> fetchAlertValue: ===")
                if (is_verbose) console.log(res);
                let newSavedSignal = underscoreToCamelCase(res);
                newSavedSignal['queryData'] = JSON.parse(newSavedSignal['queryData']);
                newSavedSignal = newSavedSignal as SignalAlertConfigurationEntity;
                newSavedSignal['delay'] = Number.parseInt(newSavedSignal['delay'] as string, 10);

                if (is_verbose) console.log("=== on @handleOnSaveAlert() -> saveAlert -> newSavedSignal: ===");
                if (is_verbose) console.log(newSavedSignal);
                setValue(newSavedSignal);
                setSavedValue(newSavedSignal);
                setOldParams(JSON.parse(newSavedSignal.params));
                setOldQueryData(newSavedSignal.queryData);

                setIsParamsChanged(checkIsParamsNotEqualsOldParams(params, JSON.parse(newSavedSignal.params)));
                setIsQueryDataChanged(checkIsQueryDataChanged(queryData, newSavedSignal.queryData));

                // loading latest saved signal alert value finished
                setLoading(false);
                setShowAlertAfterSaveOrUpdateSuccess(true);
                store.dispatch(addToastForSignalNotification('success', 'Save signal', 'create signal success.'));
                // pre-refresh chart list 
                props.listPageRefreshData();
              }).catch((e) => {
                if (is_verbose) console.log(e);
                setLoading(false);
              });
            }
          }).catch((e) => {
            if (is_verbose) console.log(e);
            setSaving(false);
            store.dispatch(addToastForSignalNotification('error', 'Save alert failed', `save alert failed, with error message: ${e.statusText}`));
          });
        }).catch((e) => {
          if (is_verbose) console.log(e);
          setSaving(false);
          store.dispatch(addToastForSignalNotification('error', 'Save alert failed', `save alert failed, with error message: ${e.statusText}`));
        });
      }
    }
  };

  /**
   * The SignalAlertConfigurationControl data changed
   * @param props 
   * @param value 
   */
  const handleSignalAlertConfigurationControlChange = (props: SignalAlertConfigurationProps, newState: SignalAlertConfigurationEntity) => {
    // const tomorrowZeroOClock = `${new Date(new Date().getTime() + 1000 * 60 * 60 *24).toISOString().slice(0, 10)}T00:00:00.000Z`;
    const todayZeroOClock = formatDateKeepTimeChangeZoneToUTC(new Date());

    const newValue = { ...newState };
    if (is_verbose) console.log("=== on @handleSignalAlertConfigurationControlChange() -> #value===");
    if (value) {
      if (value.signalMeasureContentList === undefined || newValue.signalMeasureContentList === undefined) {
        newValue.ingestionStartTime = todayZeroOClock;
      }

      if (newValue.ingestionStartTime === undefined) {
        newValue.ingestionStartTime = todayZeroOClock;
      }

      if (value.status !== newValue.status && newValue.status === SignalStatus.ACTIVE) {
        newValue.ingestionStartTime = todayZeroOClock;
      }
    }
    setValue(newValue);
    if (is_verbose) console.log(value);
  };

  // === on value:SignalAlertConfigurationEntity|undefined changed ===
  useEffect(() => {
    if (props.onChange) {
      props.onChange(props, value);
    }

    // check is SignalMeasureContentListChanged, and update the isSignalMeasureContentListChanged, it will be used to judge updateAlert or saveAlert
    if (savedValue && value) {
      setIsSignalMeasureContentListChanged(checkIsSignalMeasureContentListChanged(savedValue.signalMeasureContentList, value.signalMeasureContentList));
    }
  }, [value]);

  // === on isDisabled changed ===
  useEffect(() => {
    setIsDisabled(props.isDisabled);
  }, [props.isDisabled]);




  // === on Mount ===
  // check is saved alert exists, if yes, return a warning the alert already exists, and don't update any state variable, then disabled alert.
  //   --> guide user to delete alert first, then create a new one.
  // fetch alert value from backend when component mounted
  const mounting = useRef(true);
  // const needRefresh = useRef(false);
  useEffect(() => {
    if (is_verbose) console.log("=== on mount ===");

    if (mounting.current) {
      // first mounting add queryData to oldQueryData
      // setOldParams(params);

      mounting.current = false;
      if (loading && queryData) {
        if (is_verbose) console.log("=== on mount -> params: ===")
        if (is_verbose) console.log(params);
        const { slice_id } = params;
        if (is_verbose) console.log("=== on mount -> slice_id, ", slice_id, " ===");
        if (is_verbose) console.log("=== on mount -> loading, ", loading, " ===");
        setLoading(true);

        // load saved signal alert value from backend
        fetchAlertValue(slice_id).then((json) => {
          if (is_verbose) console.log("=== on mount -> fetchAlertValue: ===");
          if (is_verbose) console.log(json);
          let savedSignal = underscoreToCamelCase(json);
          savedSignal['queryData'] = JSON.parse(savedSignal['queryData']);
          savedSignal = savedSignal as SignalAlertConfigurationEntity;
          savedSignal['delay'] = Number.parseInt(savedSignal['delay'] as string, 10);
          if (is_verbose) console.log("=== on mount -> underscoreToCamelCase(savedSignal): ===");
          if (is_verbose) console.log(savedSignal);

          setValue(savedSignal);
          setSavedValue(savedSignal);
          setOldParams(JSON.parse(savedSignal.params));
          setOldQueryData(savedSignal.queryData);
          // return res;
          setLoading(false);
          setDbsIdFk(savedSignal.dbsIdFk);
          // TODO FIXME check is params changed

        }).catch((e) => {
          // If no data found, means no data, then use the default props.queryData to generate the default value, 
          //   do nothing here
          if (is_verbose) console.log(e);
          setLoading(false);
          setOldParams(params);
          setOldQueryData(queryData);
        });
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (is_verbose) console.log(`=== on mount -> mounting.current, ${mounting.current} === `);
    }
  });

  // === on queryData changed ===
  useEffect(() => {
    if (is_verbose) console.log("=== on queryData/oldQueryData changed ===");
    if (savedValue && oldQueryData && queryData) {
      setIsQueryDataChanged(checkIsQueryDataChanged(oldQueryData, queryData));
    }
    if (is_verbose) console.log("=== on queryData/oldQueryData changed -> queryData ===");
    if (is_verbose) console.log(JSON.stringify(queryData));
  }, [queryData, oldQueryData]);

  // === on params(form_data) changed ===
  useEffect(() => {
    if (is_verbose) console.log("=== on params(form_data) changed ===");
    if (savedValue && oldParams && params) {
      setIsParamsChanged(checkIsParamsNotEqualsOldParams(oldParams, params));
    }
    if (is_verbose) console.log("=== on params/oldParams changed -> params ===");
    if (is_verbose) console.log(JSON.stringify(params));
  }, [params, oldParams]);

  useEffect(() => {
    setParams(params);
  }, [props.params]);

  const theme = supersetTheme;
  const rowCss = css`
    display: flex;
    align-items: center;
    margin-top: ${theme.gridUnit * 2}px;
  `;

  const hyperLinkCss = css`
    padding-Left: ${theme.gridUnit * 2}px;
    &:hover {
      text-decoration: underline;
    }
  `;

  const getSignalMainPageDocLink = () => 'https://onedrive.visualstudio.com/ODSP%20Wiki/_wiki/wikis/OSDP-Wiki.wiki/95571/Signal';
  return (
    <Form
      name="signal-alerts-control-form"
      // onFinish={(values) => {
      //   handleOnSaveAlert(getUsedParams(), value);
      // }}
      onFinishFailed={(errorInfo) => {
        console.log(errorInfo);
      }}
    >
      <div
        data-test="SignalAlertsControl" className="SignalAlertsControl"
        css={{
          marginLeft: theme.gridUnit * 2,
          marginRight: theme.gridUnit * 2,
          marginBottom: theme.gridUnit * 16
        }}
      >
        {isDisabled && (
          <div className="text-muted">Don't have edit permission.</div>
        )}
        {!props.isChartSaved && checkIsReadonly() &&
          <Alert
            message={t('Your chart hasn\'t been saved yet, save it first.')}
            type="warning"
          />
        }
        {props.chartIsStale && checkIsReadonly() &&
          <Alert
            message={t('Your chart is stale, \'Update Chart\' first.')}
            type="warning"
          />
        }
        {isParamsChanged && !checkIsReadonly() &&
          <Alert
            message={[WARNING_MESSAGE_FORM_DATA_CHANGED].join('')}
            type="warning"
          />
        }
        {!isParamsChanged && isQueryDataChanged && !checkIsReadonly() &&
          <Alert
            message={[INFO_MESSAGE_QUERY_DATA_CHANGED].join('')}
            type="info"
          />
        }
        {/* { !checkIsSeriesLimitValid() &&
          <Alert
            message={[ERROR_MESSAGE_SERIES_LIMIT_IS_REQUIRED].join('')}
            type="warning"
          />
        } */}
        {!checkIsGranValid() &&
          <Alert
            message={[ERROR_MESSAGE_GRANULARITY_NOT_VALID].join('')}
            type="warning"
          />
        }
        <Row
          css={rowCss}
        >
          <Col span={24}>
            {value && oldQueryData && oldParams &&
              <SignalAlertConfigurationControl
                {...value}
                dbsIdFk={dbsIdFk}
                params={getUsedParams()}
                queryData={normalizeQueryData(getUsedQueryData())}
                columnsFormatInfo={columnsFormatInfo}
                maxSeriesNumber={maxSeriesNumber}
                isReadonly={checkIsReadonly()}
                onChange={(props: SignalAlertConfigurationProps, newState: SignalAlertConfigurationEntity) => handleSignalAlertConfigurationControlChange(props, newState)}
              />
            }
            {/* {!value && oldQueryData && oldParams && checkIsSeriesLimitValid() && checkIsGranValid() && !loading &&  */}
            {!value && oldQueryData && oldParams && checkIsGranValid() && !loading &&
              <SignalAlertConfigurationControl
                dbsIdFk={dbsIdFk}
                params={getUsedParams()}
                queryData={normalizeQueryData(getUsedQueryData())}
                columnsFormatInfo={columnsFormatInfo}
                isReadonly={checkIsReadonly()}
                maxSeriesNumber={maxSeriesNumber}
                onChange={(props: SignalAlertConfigurationProps, newState: SignalAlertConfigurationEntity) => handleSignalAlertConfigurationControlChange(props, newState)}
              />
            }
          </Col>
        </Row>

        <Row
          css={rowCss}
        >
          <Col span={24}>
            <Form.Item>
              <Button
                css={{
                  display: 'inline-flex',
                  
                }}
                buttonStyle='primary'
                htmlType='submit'
                onClick={() => handleOnSaveAlert(getUsedParams(), value)}
                disabled={checkIsReadonly() || !checkIsReadyToSave(false, value)}
              >Save Signal</Button>
              { showAlertAfterSaveOrUpdateSuccess && 
                <Alert
                  css={{
                    display: 'inline-flex',
                    marginLeft: theme.gridUnit * 2,
                    paddingTop: '3px',
                    paddingBottom: '3px',
                  }}
                  showIcon={false}
                  message={["sucessfully saved"].join('')}
                  type="success"
                  onClose={() => setShowAlertAfterSaveOrUpdateSuccess(false)}
                />
              }
            </Form.Item>
          </Col>
        </Row>
        
        <>
          <Alert
            message={[INFO_MESSAGE_ALL_DETECTION_PAY_ATTENTION].join('')}
            type="info"
            onClose={() => setShowAlertAfterSaveOrUpdateSuccess(false)}
          />
          <a href={getSignalMainPageDocLink()}
            css={hyperLinkCss}
            target="_blank"
            rel="noopener noreferrer">
            Learn more
          </a>
        </>
      </div>
    </Form>
  );

});

export default withTheme(SignalAlertsControl);