/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import FormItem from 'src/components/Form/FormItem';
import { Select, AutoComplete, AntdInput } from 'src/components';
import {
  hasGenericChartAxes,
  styled,
  SupersetClient,
  SupersetTheme,
  t,
} from '@superset-ui/core';
import debounce from 'lodash/debounce';
// import { FAST_DEBOUNCE } from 'src/constants';
import { notification } from 'antd';
import 'antd/dist/antd.css';
import {
  Operators,
  OPERATORS_OPTIONS,
  HAVING_OPERATORS,
  MULTI_OPERATORS,
  CUSTOM_OPERATORS,
  DISABLE_INPUT_OPERATORS,
  AGGREGATES,
  OPERATOR_ENUM_TO_OPERATOR_TYPE,
} from 'src/explore/constants';
import FilterDefinitionOption from 'src/explore/components/controls/MetricControl/FilterDefinitionOption';
import AdhocFilter from 'src/explore/components/controls/FilterControl/AdhocFilter';
import { isArray } from 'lodash';
import {
  ColumnMeta,
  Dataset,
  isTemporalColumn,
} from '@superset-ui/chart-controls';
import useAdvancedDataTypes from './useAdvancedDataTypes';
import { useDefaultTimeFilter } from '../../DateFilterControl/utils';
import { CLAUSES, EXPRESSION_TYPES } from '../types';

export const StyledFormItem = styled(FormItem)`
  &.ant-row.ant-form-item {
    margin: 0;
  }
`;

export interface SimpleExpressionType {
  expressionType: keyof typeof EXPRESSION_TYPES;
  column: ColumnMeta;
  aggregate: keyof typeof AGGREGATES;
  label: string;
}
export interface SQLExpressionType {
  expressionType: keyof typeof EXPRESSION_TYPES;
  sqlExpression: string;
  label: string;
}

export interface MetricColumnType {
  saved_metric_name: string;
}

export interface CohortType {
  cohort_name: string;
  label?: string;
}

export type ColumnType =
  | ColumnMeta
  | SimpleExpressionType
  | SQLExpressionType
  | MetricColumnType
  | CohortType;

export interface Props {
  adhocFilter: AdhocFilter;
  onChange: (filter: AdhocFilter) => void;
  options: ColumnType[];
  datasource: Dataset;
  partitionColumn: string;
  operators?: Operators[];
  validHandler: (isValid: boolean) => void;
  disableFetchFromCache?: boolean;
}

export interface AdvancedDataTypesState {
  parsedAdvancedDataType: string;
  advancedDataTypeOperatorList: string[];
  errorMessage: string;
}

interface InValue {
  value: string | number | boolean | null;
  label: string;
}

export const useSimpleTabFilterProps = (props: Props) => {
  const defaultTimeFilter = useDefaultTimeFilter();

  const isOperatorRelevant = (operator: Operators, subject: string) => {
    const column = props.datasource.columns?.find(
      col => col.column_name === subject,
    );
    const isColumnBoolean =
      !!column &&
      (column.type === 'BOOL' ||
        column.type === 'BOOLEAN' ||
        column.type === 'INT8');
    const isColumnNumber =
      !!column && (column.type === 'INT' || column.type === 'INTEGER');
    const isColumnFunction = !!column && !!column.expression;

    if (operator && operator === Operators.LATEST_PARTITION) {
      const { partitionColumn } = props;
      return partitionColumn && subject && subject === partitionColumn;
    }
    if (operator && operator === Operators.TEMPORAL_RANGE) {
      // hide the TEMPORAL_RANGE operator
      return false;
    }
    if (
      operator === Operators.IS_TRUE ||
      operator === Operators.IS_FALSE ||
      // For bollean type column, only show 'Is true', 'Is false', 'Is Empty, 'Is not Empty'
      operator === Operators.IS_BOOLEAN_EMPTY ||
      operator === Operators.IS_BOOLEAN_NOT_EMPTY
    ) {
      return isColumnBoolean || isColumnNumber || isColumnFunction;
    }
    if (isColumnBoolean) {
      return false;
    }
    return (
      props.adhocFilter.clause !== CLAUSES.HAVING ||
      HAVING_OPERATORS.indexOf(operator) !== -1
    );
  };

  const defaultSimpleFilter = {
    cohortSubject: undefined,
    cohort: undefined,
    cohortOperator: undefined,
    cohortOperatorId: undefined,
    cohortComparator: undefined,
    currentEditTab: 'SIMPLE',
  };

  const onSubjectChange = (id: string) => {
    const option = props.options.find(
      option =>
        ('column_name' in option && option.column_name === id) ||
        ('optionName' in option && option.optionName === id),
    );
    let subject = '';
    let clause;
    // infer the new clause based on what subject was selected.
    if (option && 'column_name' in option) {
      subject = option.column_name;
      clause = CLAUSES.WHERE;
    } else if (option && 'saved_metric_name' in option) {
      subject = option.saved_metric_name;
      clause = CLAUSES.HAVING;
    } else if (option?.label) {
      subject = option.label;
      clause = CLAUSES.HAVING;
    }
    let { operator, operatorId, comparator } = props.adhocFilter;
    operator =
      operator && operatorId && isOperatorRelevant(operatorId, subject)
        ? OPERATOR_ENUM_TO_OPERATOR_TYPE[operatorId].operation
        : null;

    if (hasGenericChartAxes && isTemporalColumn(id, props.datasource)) {
      subject = id;
      operator = Operators.TEMPORAL_RANGE;
      operatorId = Operators.TEMPORAL_RANGE;
      comparator = defaultTimeFilter;
    }

    props.onChange(
      props.adhocFilter.duplicateWith({
        subject,
        clause,
        operator,
        expressionType: EXPRESSION_TYPES.SIMPLE,
        operatorId,
        comparator,
        column: option,
        ...defaultSimpleFilter,
      }),
    );
  };
  const onOperatorChange = (operatorId: Operators) => {
    const currentComparator = props.adhocFilter.comparator;
    let newComparator;
    // convert between list of comparators and individual comparators
    // (e.g. `in ('North America', 'Africa')` to `== 'North America'`)
    if (MULTI_OPERATORS.has(operatorId)) {
      newComparator = Array.isArray(currentComparator)
        ? currentComparator
        : [currentComparator].filter(element => element);
    } else {
      newComparator = Array.isArray(currentComparator)
        ? currentComparator[0]
        : currentComparator;
    }
    if (operatorId === Operators.IS_TRUE || operatorId === Operators.IS_FALSE) {
      newComparator = Operators.IS_TRUE === operatorId ? 1 : 0;
    }
    if (
      operatorId === Operators.IS_BOOLEAN_EMPTY ||
      operatorId === Operators.IS_BOOLEAN_NOT_EMPTY
    ) {
      newComparator = undefined;
    }
    if (operatorId && CUSTOM_OPERATORS.has(operatorId)) {
      props.onChange(
        props.adhocFilter.duplicateWith({
          subject: props.adhocFilter.subject,
          clause: CLAUSES.WHERE,
          operatorId,
          operator: OPERATOR_ENUM_TO_OPERATOR_TYPE[operatorId].operation,
          expressionType: EXPRESSION_TYPES.SQL,
          datasource: props.datasource,
          ...defaultSimpleFilter,
        }),
      );
    } else {
      props.onChange(
        props.adhocFilter.duplicateWith({
          operatorId,
          operator: OPERATOR_ENUM_TO_OPERATOR_TYPE[operatorId].operation,
          comparator: newComparator,
          expressionType: EXPRESSION_TYPES.SIMPLE,
          ...defaultSimpleFilter,
        }),
      );
    }
  };
  const onComparatorChange = (comparator: any) => {
    props.onChange(
      props.adhocFilter.duplicateWith({
        comparator,
        expressionType: EXPRESSION_TYPES.SIMPLE,
        ...defaultSimpleFilter,
      }),
    );
  };
  const clearOperator = (): void => {
    props.onChange(
      props.adhocFilter.duplicateWith({
        operatorId: undefined,
        operator: undefined,
        ...defaultSimpleFilter,
      }),
    );
  };

  const isTest = '0';
  const setAutocompleteUrl = (
    str: string,
    type: string,
    id: number,
    col: any,
    isCaseSensitive = 0,
  ): string => {
    if (str && str.length > 0) {
      return `/nezha/filter/${type}/${id}/${col}/${btoa(
        str,
      )}/${isCaseSensitive}/${isTest}/`;
    }
    return `/nezha/filter/${type}/${id}/${col}/${isCaseSensitive}/${isTest}/`;
  };
  const getAutocompleteCacheState = (json: any): string => json.state;

  return {
    onSubjectChange,
    onOperatorChange,
    onComparatorChange,
    isOperatorRelevant,
    clearOperator,
    // onDatePickerChange,
    setAutocompleteUrl,
    getAutocompleteCacheState,
  };
};

const AdhocFilterEditPopoverSimpleTabContent: React.FC<Props> = props => {
  const {
    onSubjectChange,
    onOperatorChange,
    isOperatorRelevant,
    onComparatorChange,
    // onDatePickerChange,
    clearOperator,
    setAutocompleteUrl,
    getAutocompleteCacheState,
  } = useSimpleTabFilterProps(props);
  const [suggestions, setSuggestions] = useState<Record<string, any>>([]);
  const [comparator, setComparator] = useState(props.adhocFilter.comparator);
  // const [loadingComparatorSuggestions, setLoadingComparatorSuggestions] =
  //   useState(false);
  const [firstFocusInputComparator, setFirstFocusInputComparator] =
    useState(false);

  const fetchRef = useRef(0);
  const defaultDelay = 350;
  const [debounceDelay, setDebounceDelay] = useState(defaultDelay);

  // the basic logic Provided by a diccussion with Catherine.
  // according to the data card to ajust the debounce, this can help reduce the large redis set request count
  // the debounce will from 350 to 350 + 350 milliseconds
  const buildDebounceDelay = (value: number) => {
    const minValue = 10000.0;
    const maxValue = 3000000;
    return (
      defaultDelay +
      Math.min(Math.round((250 * value) / (maxValue - minValue)), 350)
    );
  };

  const {
    advancedDataTypesState,
    subjectAdvancedDataType,
    fetchAdvancedDataTypeValueCallback,
    fetchSubjectAdvancedDataType,
  } = useAdvancedDataTypes(props.validHandler);
  // TODO: This does not need to exist, just use the advancedTypeOperatorList list
  const isOperatorRelevantWrapper = (operator: Operators, subject: string) =>
    subjectAdvancedDataType
      ? isOperatorRelevant(operator, subject) &&
        advancedDataTypesState.advancedDataTypeOperatorList.includes(operator)
      : isOperatorRelevant(operator, subject);

  const renderSubjectOptionLabel = (option: any) => (
    <FilterDefinitionOption option={option} />
  );

  const handleSubjectChange = (subject: string) => {
    setComparator(undefined);
    onSubjectChange(subject);
  };

  let columns = props.options;
  const { subject, operator, operatorId } = props.adhocFilter;

  const subjectSelectProps = {
    ariaLabel: t('Select subject'),
    value: subject ?? undefined,
    onChange: handleSubjectChange,
    notFoundContent: t(
      "No such column found. The column may not exist or it's a sensitive column keyword. To filter on a metric, try the Custom SQL tab.",
    ),
    autoFocus: !subject,
    placeholder: '',
  };

  subjectSelectProps.placeholder =
    props.adhocFilter.clause === CLAUSES.WHERE
      ? t('%s column(s)', columns.length)
      : t('To filter on a metric, use Custom SQL tab.');

  const sensitiveColumns = props.datasource?.sensitive_columns || [];
  columns = props.options.filter(
    option =>
      'column_name' in option &&
      option.column_name &&
      !sensitiveColumns.includes(option?.column_name?.toLowerCase()),
  );

  const operatorSelectProps = {
    placeholder: t(
      '%s operator(s)',
      (props.operators ?? OPERATORS_OPTIONS).filter(op =>
        isOperatorRelevantWrapper(op, subject),
      ).length,
    ),
    value: operatorId,
    onChange: onOperatorChange,
    autoFocus: !!subjectSelectProps.value && !operator,
    ariaLabel: t('Select operator'),
  };

  const showNotification = (type: string, msg: string, desc: string) => {
    notification[type]({ message: msg, description: desc });
  };

  // TODO
  const fetchMultiCompleteValue = (comparator: string) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setSuggestions([]);

    console.log(comparator);
    const { datasource } = props;
    const col = props.adhocFilter.subject;
    const isOperatorValid =
      props.adhocFilter.clause === CLAUSES.HAVING ||
      props.adhocFilter.clause === CLAUSES.WHERE;

    // if disabed cache, return directly
    if (props.disableFetchFromCache) {
      return;
    }

    if (!!col && !!datasource && isOperatorValid) {
      const substr = comparator;
      const isCaseSensitive = 0;
      setAutocompleteLoading(true);
      const unwrappedCol = col.trim().replace(/^"|"$/g, '');
      SupersetClient.get({
        endpoint: `/nezha/filter/${datasource.type}/${datasource.id}/${unwrappedCol}/cache/check/`,
      })
        .then(({ json }) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }

          const state = getAutocompleteCacheState(json);
          const { card } = json;
          if (card) {
            setDebounceDelay(buildDebounceDelay(card));
          }

          switch (state) {
            case 'cache.autocomplete.service.error':
              setAutocompleteLoading(false);
              showNotification('error', 'This is error message', state);
              break;
            case 'cache.autocomplete.service.not_ready':
              setAutocompleteLoading(false);
              showNotification('warning', 'This is warning message', state);
              break;
            case 'cache.autocomplete.column.enabled':
              SupersetClient.get({
                endpoint: setAutocompleteUrl(
                  substr,
                  datasource.type,
                  datasource.id,
                  unwrappedCol,
                  isCaseSensitive,
                ),
              })
                .then(({ json }) => {
                  if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                  }

                  console.log('json : ', json);
                  setSuggestions(json);
                  setAutocompleteLoading(false);
                })
                .catch(e => {
                  console.log('error : ', e);
                  setSuggestions([]);
                  setAutocompleteLoading(false);
                });
              break;
            case 'cache.autocomplete.column.disabled':
              setAutocompleteLoading(false);
              break;
            case 'cache.autocomplete.table.disabled':
              setAutocompleteLoading(false);
              break;
            default:
              setAutocompleteLoading(false);
              break;
          }
        })
        .catch(e => {
          setAutocompleteLoading(false);
          console.log('error in check for cache : ', e);
        });
    }
  };

  const debounceFetchMultiCompleteValue = debounce(
    fetchMultiCompleteValue,
    debounceDelay,
  );

  useEffect(
    () => () => debounceFetchMultiCompleteValue.cancel(),
    [debounceFetchMultiCompleteValue],
  );

  const [autocompleteLoading, setAutocompleteLoading] = useState(false);

  const focusMultSelectComparator = (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setSuggestions([]);

    console.log(event);
    const { datasource } = props;
    const col = props.adhocFilter.subject;

    const controller = new AbortController();
    const { signal } = controller;

    const substr = event.target.value;
    const isCaseSensitive = 0;

    // if disabed cache, return directly
    if (props.disableFetchFromCache) {
      return;
    }

    if (!!col && !!datasource) {
      setAutocompleteLoading(true);
      const unwrappedCol = col.trim().replace(/^"|"$/g, '');
      SupersetClient.get({
        endpoint: `/nezha/filter/${datasource.type}/${datasource.id}/${unwrappedCol}/cache/check/`,
      })
        .then(({ json }) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }

          const state = getAutocompleteCacheState(json);
          const { card } = json;
          if (card) {
            setDebounceDelay(buildDebounceDelay(card));
          }
          console.log(state);
          switch (state) {
            case 'cache.autocomplete.service.error':
              setAutocompleteLoading(false);
              showNotification('error', 'This is error message', state);
              break;
            case 'cache.autocomplete.service.not_ready':
              setAutocompleteLoading(false);
              showNotification('warning', 'This is warning message', state);
              break;
            case 'cache.autocomplete.column.enabled':
              SupersetClient.get({
                signal,
                endpoint: setAutocompleteUrl(
                  substr,
                  datasource.type,
                  datasource.id,
                  unwrappedCol,
                  isCaseSensitive,
                ),
              })
                .then(({ json }) => {
                  if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                  }

                  console.log('focusInputComparator json : ', json);
                  setSuggestions(json);
                  console.log(
                    'focusInputComparator suggesions : ',
                    suggestions,
                  );
                  setFirstFocusInputComparator(true);
                  setAutocompleteLoading(false);
                })
                .catch(e => {
                  setAutocompleteLoading(false);
                  console.log('focusInputComparator error : ', e);
                });
              break;
            case 'cache.autocomplete.column.disabled':
            case 'cache.autocomplete.table.disabled':
            default:
              setDebounceDelay(defaultDelay);
              setSuggestions([]);
              setAutocompleteLoading(false);
              break;
          }
        })
        .catch(e => {
          setAutocompleteLoading(false);
          console.log('focusInputComparator check cache error : ', e);
        });
    }
  };

  const handleInputComparator = (comparator: string, subject: string) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setSuggestions([]);

    console.log(comparator);
    // onComparatorChange(comparator);

    const { datasource } = props;
    const col = subject;
    const isOperatorValid =
      props.adhocFilter.clause === CLAUSES.HAVING ||
      props.adhocFilter.clause === CLAUSES.WHERE;

    // if disabed cache, return directly
    if (props.disableFetchFromCache) {
      return;
    }

    if (!!col && !!datasource && isOperatorValid) {
      const substr = comparator;
      const isCaseSensitive = 0;

      setAutocompleteLoading(true);
      const unwrappedCol = col.trim().replace(/^"|"$/g, '');
      SupersetClient.get({
        endpoint: `/nezha/filter/${datasource.type}/${datasource.id}/${unwrappedCol}/cache/check/`,
      })
        .then(({ json }) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }

          const state = getAutocompleteCacheState(json);
          const { card } = json;
          if (card) {
            setDebounceDelay(buildDebounceDelay(card));
          }

          switch (state) {
            case 'cache.autocomplete.service.error':
              setAutocompleteLoading(false);
              showNotification('error', 'This is error message', state);
              break;
            case 'cache.autocomplete.service.not_ready':
              setAutocompleteLoading(false);
              showNotification('warning', 'This is warning message', state);
              break;
            case 'cache.autocomplete.column.enabled':
              SupersetClient.get({
                endpoint: setAutocompleteUrl(
                  substr,
                  datasource.type,
                  datasource.id,
                  unwrappedCol,
                  isCaseSensitive,
                ),
              })
                .then(({ json }) => {
                  if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                  }

                  console.log('json : ', json);
                  setSuggestions(json);
                  setAutocompleteLoading(false);
                })
                .catch(e => {
                  console.log('error : ', e);
                  setSuggestions([]);
                  setAutocompleteLoading(false);
                });
              break;
            case 'cache.autocomplete.column.disabled':
              setAutocompleteLoading(false);
              break;
            case 'cache.autocomplete.table.disabled':
              setAutocompleteLoading(false);
              break;
            default:
              setAutocompleteLoading(false);
              break;
          }
        })
        .catch(e => {
          setAutocompleteLoading(false);
          console.log('error in check for cache : ', e);
        });
    }
  };

  const debounceHandleInputComparator = debounce(
    handleInputComparator,
    debounceDelay,
  );
  const debouncedSearch = useCallback(debounceHandleInputComparator, []);

  useEffect(
    () => () => debounceHandleInputComparator.cancel(),
    [debounceHandleInputComparator],
  );

  const handleSearch = (value: string) => {
    console.log(value, comparator);
    const { subject } = props.adhocFilter;
    onComparatorChange(value);
    setComparator(value);
    debouncedSearch(value, subject); // Invoke the debounced function
    // debounceHandleInputComparator(value);
  };

  const focusInputComparator = (event: React.FocusEvent<HTMLInputElement>) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setSuggestions([]);

    console.log(event);
    const { datasource } = props;
    const col = props.adhocFilter.subject;

    const controller = new AbortController();
    const { signal } = controller;

    const substr = event.target.value;
    const isCaseSensitive = 0;

    // if disabed cache, return directly
    if (props.disableFetchFromCache) {
      return;
    }

    if (!!col && !!datasource) {
      setAutocompleteLoading(true);
      const unwrappedCol = col.trim().replace(/^"|"$/g, '');
      SupersetClient.get({
        endpoint: `/nezha/filter/${datasource.type}/${datasource.id}/${unwrappedCol}/cache/check/`,
      })
        .then(({ json }) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }

          const state = getAutocompleteCacheState(json);
          const { card } = json;
          if (card) {
            setDebounceDelay(buildDebounceDelay(card));
          }
          console.log(state);
          switch (state) {
            case 'cache.autocomplete.service.error':
              setAutocompleteLoading(false);
              showNotification('error', 'This is error message', state);
              break;
            case 'cache.autocomplete.service.not_ready':
              setAutocompleteLoading(false);
              showNotification('warning', 'This is warning message', state);
              break;
            case 'cache.autocomplete.column.enabled':
              SupersetClient.get({
                signal,
                endpoint: setAutocompleteUrl(
                  substr,
                  datasource.type,
                  datasource.id,
                  unwrappedCol,
                  isCaseSensitive,
                ),
              })
                .then(({ json }) => {
                  if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                  }

                  console.log('focusInputComparator json : ', json);
                  setSuggestions(json);
                  console.log(
                    'focusInputComparator suggesions : ',
                    suggestions,
                  );
                  setFirstFocusInputComparator(true);
                  setAutocompleteLoading(false);
                })
                .catch(e => {
                  setAutocompleteLoading(false);
                  console.log('focusInputComparator error : ', e);
                });
              break;
            case 'cache.autocomplete.column.disabled':
            case 'cache.autocomplete.table.disabled':
            default:
              setSuggestions([]);
              setAutocompleteLoading(false);
              break;
          }
        })
        .catch(e => {
          setAutocompleteLoading(false);
          console.log('focusInputComparator check cache error : ', e);
        });
    }
  };

  const isDebug = false;
  if (isDebug !== undefined && isDebug !== false) {
    console.log(DISABLE_INPUT_OPERATORS);
    console.log(clearOperator);
    console.log(comparator);
    console.log(firstFocusInputComparator);
    console.log(fetchAdvancedDataTypeValueCallback);
    console.log(fetchSubjectAdvancedDataType);
  }

  const noValueOperators = [
    'IS_EMPTY',
    'IS_NOT_EMPTY',
    'IS_FALSE',
    'IS_TRUE',
    'IS_NULL',
    'IS_NOT_NULL',
    // Hide input box for 'Is empty' and 'Is not empty' operators.
    'IS_BOOLEAN_EMPTY',
    'IS_BOOLEAN_NOT_EMPTY',
  ];

  const isNoValueOperator = () =>
    noValueOperators.some(op => op === operatorSelectProps.value);
  const isMultiSelectOperator = () =>
    operatorSelectProps.value === 'IN' ||
    operatorSelectProps.value === 'NOT_IN';
  const multiSelectValueConvert = (comparator: any | any[]) => {
    if (isArray(comparator)) {
      return comparator.map(value => ({ value, label: value }));
    }
    return [{ value: comparator, label: comparator }];
  };

  // another name for columns, just for following previous naming.
  const subjectComponent = (
    <Select
      css={(theme: SupersetTheme) => ({
        marginTop: theme.gridUnit * 4,
        marginBottom: theme.gridUnit * 4,
      })}
      data-test="select-element"
      options={columns.map(column => ({
        value:
          ('column_name' in column && column.column_name) ||
          ('optionName' in column && column.optionName) ||
          '',
        label:
          ('saved_metric_name' in column && column.saved_metric_name) ||
          ('column_name' in column && column.column_name) ||
          ('label' in column && column.label),
        key:
          ('id' in column && column.id) ||
          ('optionName' in column && column.optionName) ||
          undefined,
        customLabel: renderSubjectOptionLabel(column),
      }))}
      {...subjectSelectProps}
    />
  );

  const operatorsAndOperandComponent = (
    <>
      <Select
        css={(theme: SupersetTheme) => ({ marginBottom: theme.gridUnit * 4 })}
        options={(props.operators ?? OPERATORS_OPTIONS)
          .filter(op => isOperatorRelevantWrapper(op, subject))
          .map((option, index) => ({
            value: option,
            label: OPERATOR_ENUM_TO_OPERATOR_TYPE[option].display,
            key: option,
            order: index,
          }))}
        {...operatorSelectProps}
      />
      {isNoValueOperator() ? null : isMultiSelectOperator() ? (
        <Select
          loading={autocompleteLoading}
          labelInValue
          css={(theme: SupersetTheme) => ({ marginBottom: theme.gridUnit * 4 })}
          options={suggestions.map((suggestion: string) => ({
            value: suggestion,
            label: String(suggestion),
          }))}
          maxTagCount={7}
          mode="multiple"
          value={comparator ? multiSelectValueConvert(comparator) : []}
          onChange={newValue => {
            if (
              newValue !== null ||
              newValue !== undefined ||
              (Array.isArray(newValue) && (newValue as any[]).length !== 0)
            ) {
              setComparator((newValue as InValue[]).map(value => value.value));
              onComparatorChange(
                (newValue as InValue[]).map(value => value.value),
              );
            } else {
              setComparator([]);
              onComparatorChange([]);
            }
          }}
          onFocus={focusMultSelectComparator}
          ariaLabel={t('Input value')}
          filterOption
          allowNewOptions
          onSearch={debounceFetchMultiCompleteValue}
        />
      ) : (
        <AutoComplete
          style={{ width: '100%' }}
          options={suggestions.map((suggestion: string) => ({
            value: suggestion,
            label: String(suggestion),
          }))}
          // placeholder="Filter value"
          onChange={handleSearch}
          onFocus={focusInputComparator}
          value={props.adhocFilter?.comparator}
        >
          <AntdInput.Search
            size="middle"
            placeholder="Filter value"
            loading={autocompleteLoading}
          />
        </AutoComplete>
      )}
    </>
  );
  return (
    <>
      {subjectComponent}
      {operatorsAndOperandComponent}
      {/* {datePicker ?? operatorsAndOperandComponent} */}
    </>
  );
};

export default AdhocFilterEditPopoverSimpleTabContent;
