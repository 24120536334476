/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

/**
 * The Chart model as returned from the API
 */

import ChangedBy from './ChangedBy';
import CreatedBy from './CreatedBy';
import Owner from './Owner';
import SignalMeasureContent from './SignalMeasureContent';

export enum SignalStatus {
  DISABLED = 0,
  ACTIVE = 1,
}

export interface SignalSummary {
  id: number;
  signal_name: string;
  mode: string;
  signal_type: number;
  status: SignalStatus;
}

export interface Signal {
  id: number;
  signal_id: number;
  signal_name: string;
  created_by_name: string;
  changed_by_name: string;
  created_by: CreatedBy;
  changed_by: ChangedBy;
  description?: string;
  recipient: string;
  subject: string;
  mode: string;
  measure: string;
  dimension: string;
  signal_measure_context_list?: SignalMeasureContent[];
  series: string;
  dbs_id_fk: number;
  slice_id_fk: number;
  slice_url?: string;
  slice_name?: string;
  ingestion_start_time: string;
  granularity: string;
  signal_granularity: string;
  signal_type: number;
  status: number;
  creator: string;
  changed_on: string;
  changed_on_delta_humanized?: string;
  changed_on_utc?: string;
  owners?: Owner[];
  params?: string;
  query_data: any;
  delay?: number;
}

export default Signal;
